<!-- 变价调拨 -->
<template>
  <div class="page">
    <div class="pageTop">
      <div class="top">
        <Form inline class="formMarginBtm20">
          <FormItem>
            <span>发起仓库：</span>
            <!-- @on-select="warehouseChange" 之前的 -->
            <Select placeholder="请选择" class="iviewIptWidth250 marginRight40" v-model="warehouse_id" filterable clearable @on-change="wareChange" :disabled="editDisable">
              <Option v-for="(item, index) in storeList" :disabled="item.check" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>调拨仓库：</span>
            <Select placeholder="请选择" :disabled="type" clearable filterable class="iviewIptWidth250 marginRight40" v-model="postForm.to_warehouse_id">
              <Option v-for="(item, index) in storeList" :value="item.warehouse_id" :key="index" :disabled="item.warehouse_id == warehouse_id">{{ item.warehouse_name }}</Option>
            </Select>
          </FormItem>
          <!-- 经手人 -->
          <FormItem>
            <span>经手人：</span>
            <Select placeholder="请选择" clearable filterable class="iviewIptWidth250 marginRight40" v-model="postForm.processor_id" @on-change="processorChange">
              <Option v-for="(item, index) in dealList" :value="item.id" :key="index">{{ item.user_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>审核人：</span>
            <Select placeholder="请选择" clearable filterable class="iviewIptWidth250" v-model="postForm.reviewer_id">
              <Option v-for="(item, index) in reviewList" :value="item.id" :key="index">{{ item.user_name }}</Option>
            </Select>
          </FormItem>
          <br />
          <FormItem>
            <span>产品名称：</span>
            <Select placeholder="请选择" clearable filterable class="iviewIptWidth250 marginRight40" :disabled="warehouse_id < 0 || type" v-model="queryFrom.product_id" @on-change="productChange">
              <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>规格型号/SKU：</span>
            <Select class="iviewIptWidth250 marginRight40" :disabled="warehouse_id < 0 || type" multiple filterable clearable v-model="queryFrom.specification_id_str" :max-tag-count="queryFrom.specification_id_str.length == 1 ? 1 : 0">
              <Option v-for="(item, index) in modelList" :value="item.specification_id" :key="index" :label="item.model_name + '-' + item.item_number">
                <span>{{ item.model_name }}</span>
                <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
              </Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>批号：</span>
            <i-input :disabled="type" v-model="queryFrom.batch_number" placeholder="请输入" class="iviewIptWidth250"></i-input>
          </FormItem>
          <FormItem>
            <span class="longTextBtn finger btnSure marginLeft20" @click="query">查询库存</span>
            <span class="longTextBtn finger btnAdd marginLeft20" @click="upload">模版导入</span>
          </FormItem>
        </Form>
      </div>
      <div class="btm">
        <div class="barcode">
          <p><span class="fl">条形码</span> <span class="pageBtn finger btnSure fr" @click="queryCode">查询</span></p>
          <Input type="textarea" placeholder="请输入带入库条形码，多个产品换行显示" class="textarea" v-model="codeStr"></Input>
        </div>
        <div class="btmRight">
          <div class="checkList" v-if="type">
            <p>
              产品清单<span style="margin-left: 20px;" v-if="checkData[0]">{{ checkData[0].supplier_name }}</span>
              <span class="longTextBtn finger btnSure marginLeft20 fr" @click="automaticPicking">自动拣货</span>
            </p>
            <Table :columns="checkColumns" :data="checkData" border> </Table>
            <!-- <div  class="summary picking">暂未选择销售订单/退货通知单</div> -->
          </div>
          <p>{{ postForm.transfer_type == '1' ? '同价调拨' : '变价调拨' }} <span class="fr pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span></p>
          <TableCom :rowClass="rowClassName" :option_page="option_page" :productList="postForm.transfer_type == '1' ? listColumns1 : listColumns2" :productData="listData" border :style="{ 'min-height': listData.length == 0 ? '0px' : '400px', overflow: 'auto' }">
            <template slot-scope="{ row, index }" slot="inventory_quantity">
              <InputNumber :max="999999" :min="0" :precision="0" :value="row.inventory_quantity" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'inventory_quantity', index, row.product_name, row.model_name, row.id)"></InputNumber>
            </template>
            <template slot-scope="{ row, index }" slot="unit_price">
              <InputNumber :value="row.unit_price" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'unit_price', index)"></InputNumber>
            </template>
            <!-- 批号 -->
            <template slot-scope="{ row, index }" slot="batch_number">
              <Input placeholder="请输入" :value="row.batch_number" @on-change="changeIpt($event, 'batch_number', index)"></Input>
            </template>
            <!-- 序列号 -->
            <template slot-scope="{ row, index }" slot="serial_number">
              <Input placeholder="请输入" :value="row.serial_number" @on-change="changeIpt($event, 'serial_number', index)"></Input>
            </template>
            <!-- 生产日期 -->
            <template slot-scope="{ row, index }" slot="production_date">
              <Input @on-blur="dataBlur($event, true)" :value="row.production_date" placeholder="请输入" style="width: 150px;" @on-change="changeIpt($event, 'production_date', index)" />
              <!-- <DatePicker placeholder="请输入" type="date" format="yyyy-MM-dd" :transfer="true" :value="row.production_date" @on-change="changeInput($event, 'production_date', index)"></DatePicker> -->
            </template>
            <!-- 有效期/失效期 -->
            <template slot-scope="{ row, index }" slot="valid_period">
              <Input @on-blur="dataBlur($event)" :value="row.valid_period" placeholder="请输入" style="width: 150px;" @on-change="changeIpt($event, 'valid_period', index)" />
              <!-- <DatePicker placeholder="请输入" type="date" format="yyyy-MM-dd" :transfer="true" :value="row.valid_period" @on-change="changeInput($event, 'valid_period', index)"></DatePicker> -->
            </template>
            <!-- 调拨后单价  current_unit_price-->
            <template slot-scope="{ row, index }" slot="current_unit_price">
              <InputNumber :max="999999" :min="0" :precision="0" :value="row.current_unit_price" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'current_unit_price', index)"></InputNumber>
            </template>
          </TableCom>
          <div class="summary" v-if="totalMoney">
            <div>
              <span style="margin-right: 20px;"
                >总数量：<span class="color389">{{ totalNum }}</span></span
              >
              <span>合计金额：</span>
              <span class="color389">¥{{ totalMoney }}</span>
            </div>
          </div>
          <div class="tipsDiv">
            <div class="tipsFl">备注</div>
            <div class="tipsFr">
              <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model.trim="postForm.note"></i-input>
            </div>
          </div>
          <div class="button">
            <span class="longTextBtn finger btnSure marginLeft20 fr" @click="post">确认调拨</span>
            <span class="pageBtn finger btnReset fr" @click="back">返回</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 查询库存 -->
    <Modal v-model="inventory" :mask-closable="false" width="1260" class="modal" :footer-hide="true">
      <p slot="header" class="header">库存查询</p>
      <div class="changeNum">
        <span>调拨数量：</span>
        <InputNumber :disabled="inputFlag || !this.queryFrom.specification_id_str.length > 0 || !this.queryFrom.product_id" @on-change="changeInputNum" v-model="changeNum" placeholder="请输入调拨数量" :min="0" style="width: 300px;"></InputNumber>
        <span class="redBox">选择产品和规格型号后，填写数量可进行自动拣货</span>
      </div>
      <Table highlight-row :row-class-name="rowClassName" class="table" ref="selection" :columns="inventoryColumns" :data="inventoryData" @on-selection-change="selectChange" border height="500"></Table>
      <div class="clearfix" style="margin-top: 20px;">
        <span class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
      </div>
    </Modal>
    <!-- 自动拣货弹窗 -->
    <Modal v-model="model3" label-position="left" width="1200" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <Table :columns="checkColumns2" height="450" :data="checkData2" border>
        <template slot-scope="{ row, index }" slot="picking_quantity">
          <InputNumber :max="999999" :min="0" :precision="0" v-model="row.picking_quantity" :active-change="false" @on-change="changeIpt2($event, index)"></InputNumber>
        </template>
      </Table>
      <div class="clearfix flexBox" style="margin-top: 20px;">
        <span class="redBox">确定后系统将自动优先选择近效期产品</span>
        <span class="pageBtn finger btnSure fr" @click="automaticjh">确定</span>
      </div>
    </Modal>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="comfirStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否更换发货仓库？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="sureChangewarehouseId">确定</span>
          <span class="pageBtn finger btnCancle" @click="cancleChangewarehouseId">取消</span>
        </div>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import TableSetup from '@/components/tableSetup'
import TableCom from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
import { Delete } from '../../service/http'
import { debounce } from '@/service/utils'
export default {
  name: 'addAllocate',
  components: {
    TipsComponent,
    TableSetup,
    TableCom,
  },
  computed: {
    totalNum() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.inventory_quantity * 1
      })
      return totle
    },
    totalMoney() {
      let totle = 0
      if (this.postForm.transfer_type == '1') {
        this.listData.forEach((e, i) => {
          totle = totle * 1 + (e.unit_price ? e.unit_price * e.inventory_quantity : 0)
        })
        return totle.toFixed(2)
      } else {
        this.listData.forEach((e, i) => {
          totle = totle * 1 + (e.current_unit_price ? e.current_unit_price * e.inventory_quantity : 0)
        })
        return totle
      }
    },
  },
  data() {
    return {
      inputFlag: false,
      changeNum: 0,
      model3: false,
      checkColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200,
        },
        {
          title: '订单数量',
          key: 'quantity',
          align: 'center',
          width: 130,
        },
        {
          title: '库存余量',
          key: 'inventory_quantity',
          align: 'center',
          width: 130,
        },
        {
          title: '调拨数量',
          slot: 'picking_quantity',
          align: 'center',
          width: 150,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 100,
        },
      ],
      checkData2: [],
      // 预拣货数据
      checkData: [],
      checkColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200,
        },
        {
          title: '订单数量',
          key: 'quantity',
          align: 'center',
          width: 150,
        },
        {
          title: '调拨数量',
          key: 'picknum',
          align: 'center',
          width: 150,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          width: 200,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                    marginRight: '40px',
                  },
                  on: {
                    click: () => {
                      this.readyRow(param.row, param.index)
                    },
                  },
                },
                '预拣货'
              ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.inventoryQuery(param.row)
                    },
                  },
                },
                '库存查询'
              ),
            ])
          },
        },
      ],
      // 库存查询头部
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 120,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 120,
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
          width: 80,
        },
        {
          title: '数量',
          key: 'inventory_quantity',
          align: 'center',
          width: 70,
        },
        {
          title: '有效期',
          key: 'validPeriod',
          align: 'center',
          width: 100,
        },
        {
          title: '所属仓库',
          key: 'warehouse_name',
          align: 'center',
          width: 120,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          width: 300,
        },
      ],
      // 动态表头
      id: '',
      titleList: [],
      option_page: '32',
      setupStatus: false,
      deleteStatus: false,
      postStatus: false,
      warehouse_id: '',
      queryFrom: {
        warehouse_id: '',
        product_id: '',
        specification_id_str: [],
        warehouse_name: '',
        batch_number: '',
      },
      storeList: [],
      inventory: false,
      inventoryColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 170,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 220,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 130,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 130,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 130,
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
          width: 130,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          width: 110,
        },
        {
          title: '供货单价',
          key: 'unit_price',
          align: 'center',
          width: 100,
        },
        {
          title: '数量',
          key: 'inventory_quantity',
          align: 'center',
          width: 70,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 70,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 100,
        },
      ],
      inventoryData: [],
      total: 1,
      listData: [],
      listColumns1: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
        },
        {
          title: '条形码',
          align: 'center',
          slot: 'bar_code',
        },
        {
          title: '批号',
          align: 'center',
          slot: 'batch_number',
        },
        {
          title: '序列号',
          align: 'center',
          slot: 'serial_number',
        },
        {
          title: '数量',
          align: 'center',
          slot: 'quantity',
        },
        {
          title: '销售单价',
          align: 'center',
          key: 'unit_price',
        },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
        },
        {
          title: '条形码',
          align: 'center',
          slot: 'bar_code',
        },
        {
          title: '批号',
          align: 'center',
          slot: 'batch_number',
        },
        {
          title: '序列号',
          align: 'center',
          slot: 'serial_number',
        },
        {
          title: '数量',
          align: 'center',
          slot: 'quantity',
        },
        {
          title: '原销售单价',
          align: 'center',
          key: 'unit_price',
        },
        {
          title: '销售单价',
          align: 'center',
          slot: 'current_unit_price',
        },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      // 条形码
      // 表单拉下选项
      // 查询参数
      postForm: {
        from_warehouse_id: '',
        from_warehouse_name: '',
        to_warehouse_name: '',
        to_warehouse_id: '',
        transfer_type: '', // 1是同价  2是变价,
        processor_id: '',
        processor: '',
        reviewer_id: '',
        note: '',
      },
      dealList: [], // 经手
      reviewList: [], // 审核
      productList: [],
      modelList: [], // sku
      hadSelectList: [],
      deleteIndex: '',
      codeStr: '',
      type: false, // 销售申请单转调拨标识
      chooseStatus: false, // 库存查询框
      Number: 0,
      comfirStatus: false,
      flag: false,
      editDisable: false,
      selectRowId: null
    }
  },
  mounted() {
    this.postForm.transfer_type = this.$route.query.addType
    this.type = this.$route.query.type ? true : false
    // console.log(this.$route.query)
    if (this.type) {
      this.chooseSaleOrder(this.$route.query.id)
    }
    this.queryOption()
    this.specialUser('1')
    this.specialUser('2')
    this.queryStoreList()
  },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.warehouse_id = ''
      ;(this.postForm = {
        from_warehouse_id: '',
        from_warehouse_name: '',
        to_warehouse_name: '',
        to_warehouse_id: '',
        transfer_type: '', // 1是同价  2是变价,
        processor_id: '',
        processor: '',
        reviewer_id: '',
        note: '',
      }),
        (this.listData = [])
      this.postForm.transfer_type = this.$route.query.addType
      this.type = this.$route.query.type ? true : false
      this.queryOption()
      this.specialUser('1')
      this.specialUser('2')
      this.queryStoreList()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  methods: {
    // cancel(){
    //   // console.log('123')
    //   if(this.changeNum){

    //   }
    // },
    changeInputNum() {
      // if((!this.queryFrom.specification_id_str.length > 0) || (!this.queryFrom.product_id)){
      //   this.$Message.warning('请先选择产品和规格')
      //     return
      // }
      // console.log(this.inputFlag && this.queryFrom.product_id && this.queryFrom.specification_id_str.length > 0)
      if (!this.changeNum) {
        for (let i = 0; i < this.inventoryData.length; i++) {
          this.$set(this.inventoryData[i], '_disabled', false)
        }
      } else {
        for (let i = 0; i < this.inventoryData.length; i++) {
          this.$set(this.inventoryData[i], '_disabled', true)
        }
      }
    },
    wareChange(e) {
      // 销售单转调拨单编辑
      if (this.type) {
        this.warehouse_id = e
        if (this.listData.length > 0) {
          this.comfirStatus = true
        } else {
          this.pickingTranfrom(e)
        }
        return
      }
      // 调拨改变
      this.postForm.to_warehouse_id = ''
      this.queryFrom.product_id = ''
      this.queryFrom.specification_id_str = []
      this.warehouse_id = e
      if (e > 0) {
        this.pickingTranfrom(e)
        this.queryProduct(e, null)
      }
      this.$http.get(this.$api.inventoryCodeLookup, { product_id: this.queryFrom.product_id, warehouse_id: this.warehouse_id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 发货仓库转换
    pickingTranfrom(e) {
      if (!e) {
        this.postForm.from_warehouse_id = ''
        this.postForm.from_warehouse_name = ''
        return
      }
      for (let i = 0; i < this.storeList.length; i++) {
        if (e == this.storeList[i].warehouse_id) {
          this.postForm.from_warehouse_name = this.storeList[i].warehouse_name
          this.postForm.from_warehouse_id = this.storeList[i].warehouse_id
        }
      }
    },
    // 取消更换仓库
    cancleChangewarehouseId() {
      this.warehouse_id = this.postForm.from_warehouse_id
      this.comfirStatus = false
      this.pickingTranfrom(this.warehouse_id)
    },
    // 确认更换发货仓库
    sureChangewarehouseId() {
      this.listData = []
      // 还原拣货数量
      this.checkData.forEach(item => {
        item.picknum = item.picking_quantity
      })
      this.comfirStatus = false
      this.pickingTranfrom(this.warehouse_id)
    },
    // 仓库改变
    // warehouseChange(e) {
    //     if (this.type) {
    //         console.log(e)
    //         this.warehouse_id = e.value
    //         if(this.listData.length > 0) {
    //            this.comfirStatus = true
    //         } else {
    //           for (let i = 0; i < this.storeList.length; i++) {
    //             if (this.storeList[i].warehouse_id == e.value) {
    //                 this.postForm.from_warehouse_id = e.value
    //                 this.postForm.from_warehouse_name = this.storeList[i].warehouse_name
    //             }
    //           }
    //         }
    //         return
    //     }
    //     // 销售单转调拨单编辑
    //     if (e.value >= 0) {
    //         this.postForm.to_warehouse_id = ''
    //         this.queryFrom.product_id = ''
    //         this.queryFrom.specification_id_str = []
    //         this.warehouse_id = e.value
    //         for (let i = 0; i < this.storeList.length; i++) {
    //             if (this.storeList[i].warehouse_id == e.value) {
    //                 this.postForm.from_warehouse_id = e.value
    //                 this.postForm.from_warehouse_name = this.storeList[i].warehouse_name
    //             }
    //         }
    //         this.queryProduct(e.value, null)
    //     } else {
    //         this.postForm.to_warehouse_id = ''
    //         this.queryFrom.product_id = ''
    //         this.queryFrom.specification_id_str = []
    //     }
    //     this.$http.get(this.$api.inventoryCodeLookup, { product_id: this.queryFrom.product_id, warehouse_id: this.warehouse_id }, false).then(res => {
    //         this.modelList = res.data
    //     })
    // },
    // 自动拣货按钮
    automaticjh() {
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发货仓库')
        return null
      }
      let arr = this.checkData2.filter(item => {
        if (item.picking_quantity) {
          return item
        }
      })
      if (arr.length == 0) {
        this.$Message.warning('请输入订单数量')
        return
      }
      if (!this.model3) return // 限制连续点击
      this.model3 = false
      let obj = {
        warehouse_id: this.warehouse_id,
        line_info: arr,
        picking_type: null,
      }
      for (let i = 0; i < this.listData.length; i++) {
        for (let j = 0; j < this.checkData.length; j++) {
          if (this.listData[i].product_name == this.checkData[j].product_name && this.listData[i].product_model_code == this.checkData[j].product_model_code && this.listData[i].id == this.checkData[j].id) {
            this.$set(this.checkData[j], 'picknum', this.checkData[j].picknum - this.listData[i].inventory_quantity)
          }
        }
      }
      this.listData = []
      this.$http.put(this.$api.automaticPicking, obj, false).then(res => {
        res.data.forEach(item => {
          item.inventory_quantity = item.quantity
          item.current_unit_price = item.current_unit_price ? item.current_unit_price : 0
          this.readyRow(item, '', true)
        })
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < this.checkData.length; j++) {
            if (res.data[i].product_name == this.checkData[j].product_name && res.data[i].product_model_code == this.checkData[j].product_model_code && res.data[i].id == this.checkData[j].id) {
              this.$set(this.checkData[j], 'picknum', res.data[i].inventory_quantity + this.checkData[j].picknum)
            }
          }
        }
      })
    },
    automaticPicking() {
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发货仓库')
        return null
      }
      let arr = this.checkData.filter((item, index) => {
        if (item.pickNum != item.quantity) {
          return item
        }
      })
      let arr2 = JSON.parse(JSON.stringify(arr))
      // arr2.forEach(item => {
      //   item.picking_quantity = 0
      // })
      this.checkData2 = JSON.parse(JSON.stringify(arr2))
      if (this.checkData2.length == 0) {
        this.$Message.warning('无可拣货产品')
        return
      }
      // 获取自动拣货的仓库库存量
      let param = {
        warehouse_id: this.warehouse_id,
        line_info: this.checkData2,
      }
      this.$http.post(this.$api.automaticPicking, param, true).then(result => {
        this.checkData2 = result.data
      })
      if (this.checkData.length > 0) {
        for (let i = 0; i < this.checkData.length; i++) {
          this.checkData2[i].picking_quantity = this.checkData[i].picknum
        }
      }
      this.model3 = true
    },
    // 有效期小于180标黄
    rowClassName(row, index) {
      if (row.hasChecked) {
        return 'greenRow'
      } else if (row.warning) {
        return 'orginRow'
      }
      return ''
    },
    // 日期校验
    dataBlur(e, testing) {
      if (testing && !e.target.value) return
      let res = this.$utils.dataType(e.target.value)
      if (res.code) {
        this.$Message.warning(res.text)
      }
    },
    // 预拣货数据
    // 确认销售订单  查订单详情
    chooseSaleOrder(id) {
      this.$http.get(this.$api.warehouseTransferDetail, { id: id, dynamic_header: 1 }, true).then(res => {
        this.listData = []
        this.checkData = res.data.line_info
        this.checkData.forEach((e, i) => {
          this.checkData[i].picking_quantity = this.checkData[i].picking_quantity || 0
          this.$set(this.checkData[i], 'picknum', this.checkData[i].picking_quantity)
          // this.postForm.specification_id_str = this.checkData[i].product_id
        })
        this.warehouse_id = res.data.from_warehouse_id
        this.editDisable = res.data.from_warehouse_id == -1 ? false : true
        this.postForm.to_warehouse_id = res.data.to_warehouse_id
        this.storeList.forEach(item => {
          if (item.warehouse_id == res.data.to_warehouse_id) {
            this.$set(item, 'check', true)
          }
        })
      })
    },
    // 库存查询
    inventoryQuery(row) {
      this.selectRowId = row.id;
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发起仓库')
        return null
      }
      this.Number = row.quantity - row.picknum
      // console.log(row)
      let data = {
        product_model_code: row.product_model_code,
        product_id: row.product_id,
        warehouse_id: this.warehouse_id,
      }
      if (row.order_code) {
        data.order_code = row.order_code
        data.order_detail_id = row.order_detail_id
      } else {
        data.purchase_return_id = row.purchase_return_id
        data.id = row.id
      }
      data.picking_page = '1'
      this.hadSelectList = []
      this.$http.get(this.$api.inventoryQuery, data, true).then(res => {
        this.inventoryData = res.data
        for (let i = 0; i < this.inventoryData.length; i++) {
          this.inventoryData[i].validPeriod = this.inventoryData[i].valid_period ? this.$moment(this.inventoryData[i].valid_period * 1000).format('YYYY-MM-DD') : null
          this.inventoryData[i].licence_code = row.licence_code
          this.inventoryData[i].product_model_name = this.inventoryData[i].model_name
          // this.inventoryData[i].unit = row.product_unit
          this.inventoryData[i].production_license_valid_period = this.inventoryData[i].production_license_valid_period ? this.$moment(this.inventoryData[i].production_license_valid_period * 1000).format('YYYY-MM-DD') : null
          this.inventoryData[i].business_license_valid_period = this.inventoryData[i].business_license_valid_period ? this.$moment(this.inventoryData[i].business_license_valid_period * 1000).format('YYYY-MM-DD') : null
          this.inventoryData[i].create_time = this.inventoryData[i].create_time ? this.$moment(this.inventoryData[i].create_time * 1000).format('YYYY-MM-DD') : null
          this.inventoryData[i].end_time = this.inventoryData[i].end_time ? this.$moment(this.inventoryData[i].end_time * 1000).format('YYYY-MM-DD') : null
          this.inventoryData[i].production_record_date = this.inventoryData[i].production_record_date ? this.$moment(this.inventoryData[i].production_record_date * 1000).format('YYYY-MM-DD') : null
          this.inventoryData[i].record_valid_period = this.inventoryData[i].record_valid_period ? this.$moment(this.inventoryData[i].record_valid_period * 1000).format('YYYY-MM-DD') : null
          this.inventoryData[i].licence_valid_period = this.inventoryData[i].licence_valid_period ? this.$moment(this.inventoryData[i].licence_valid_period * 1000).format('YYYY-MM-DD') : null
          this.inventoryData[i].production_date = this.inventoryData[i].production_date ? this.$moment(this.inventoryData[i].production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : null
          this.inventoryData[i].production_date = this.inventoryData[i].production_date ? this.$utils.Timetransformation(this.inventoryData[i].production_date).replace(/-/gi, '') : null
          this.inventoryData[i].warning = this.$utils.warningDay(this.inventoryData[i].valid_period, 180)
          this.inventoryData[i].valid_period = this.inventoryData[i].valid_period ? this.$moment(this.inventoryData[i].valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : null
          this.inventoryData[i].valid_period = this.inventoryData[i].valid_period ? this.$utils.Timetransformation(this.inventoryData[i].valid_period).replace(/-/gi, '') : null
        }
        if (this.inventoryData.length > 0) {
          this.inventory = true
          // console.log(this.inventoryData, '数字')
        } else {
          this.$Message.warning('暂无数据')
        }
      })
    },
    // 预拣货
    readyRow(row, index, type) {
      if (!type) {
        this.$nextTick(() => {
          //  this.$set(this.checkData[index], 'picknum', this.checkData[index].picknum + row.inventory_quantity)
          this.$set(this.checkData[index], 'picknum', this.checkData[index].picknum + row.quantity)
          this.$forceUpdate()
          // let quantity_new = this.listData.reduce((result,res)=>{
          //   return result+res.quantity
          // },0)
          // this.$set(this.checkData[index], 'picknum', quantity_new)
          // this.$forceUpdate()
        })
        row.batch_number = ''
      }
      // 拣货数量
      row.inventory_quantity = row.quantity
      this.$set(this.listData, this.listData.length, JSON.parse(JSON.stringify(row)))
      let length = this.listData.length - 1
      // 处理时间

      // 注册证/备案凭证有效期至
      let dataStr = ['licence_valid_period', 'production_date', 'valid_period', 'production_license_valid_period', 'production_record_date', 'business_license_valid_period', 'record_valid_period', 'return_time', 'receive_time', 'accept_time', 'rec_time', 'order_time', 'picking_time', 'delivery_time']
      dataStr.forEach((item, index) => {
        if (this.listData[length][item]) {
          if (item == 'production_date' || item == 'valid_period') {
            this.listData[length][item] = this.$moment.unix(this.listData[length][item]).format('YYYY-MM-DD HH:mm:ss')
            this.listData[length][item] = this.$utils.Timetransformation(this.listData[length][item]).replace(/-/gi, '')
          } else if (item == 'business_license_valid_period' || item == 'record_valid_period') {
            if (this.listData[length][item] && this.$moment.unix(this.listData[length][item]).format('YYYY-MM-DD') != '9999-12-31') {
              this.listData[length][item] = this.$moment.unix(this.listData[length][item]).format('YYYY-MM-DD')
            } else {
              this.listData[length][item] = '长期'
            }
          } else {
            this.listData[length][item] = this.$moment.unix(this.listData[length][item]).format('YYYY-MM-DD')
          }
        }
      })

      let listArr = []
      this.checkData.forEach(item => {
        this.listData.forEach(subItem => {
          if (item.id == subItem.id) {
            listArr.push(subItem)
          }
        })
      })
      this.listData = listArr
      // 预拣货可以修改时间参数
      let arr = [
        { key: 'batch_number', name: '批号' },
        { key: 'production_date', name: '生产日期' },
        { key: 'valid_period', name: '有效期' },
        { key: 'serial_number', name: '序列号' },
      ]
      if (this.postForm.transfer_type == '1') {
        this.listColumns1.forEach((item, index) => {
          arr.forEach(item2 => {
            if (item.data_col == item2.key) {
              let obj = {
                title: item2.name,
                align: 'center',
                slot: item2.key,
                width: 150,
                resizable: true,
              }
              this.$set(this.listColumns1, index, obj)
            }
          })
        })
      } else {
        this.listColumns2.forEach((item, index) => {
          arr.forEach(item2 => {
            if (item.data_col == item2.key) {
              let obj = {
                title: item2.name,
                align: 'center',
                slot: item2.key,
                width: 150,
                resizable: true,
              }
              this.$set(this.listColumns2, index, obj)
            }
          })
        })
      }
      // 计算调拨数量
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http.get(this.$api.systemOptionItem, { option_page: this.option_page }).then(res => {
        localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
        //  console.log(res,'表头') postForm.transfer_type == '1' ? listColumns1 : listColumns2
        if (this.postForm.transfer_type == '1') {
          this.titleList = res.data.enable
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns1 = []
          this.listColumns1.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
            resizable: true,
          })
          this.listColumns1 = [...this.listColumns1, ...this.titleList]
          this.listColumns1 = JSON.parse(JSON.stringify(this.listColumns1))
          this.listColumns1.forEach((item, index) => {
            if (item.data_col == 'inventory_quantity') {
              let obj = {
                title: '数量',
                align: 'center',
                slot: 'inventory_quantity',
                width: 100,
                resizable: true,
              }
              this.$set(this.listColumns1, index, obj)
            }
          })
          this.listColumns1.push({
            title: '操作',
            align: 'center',
            width: 80,
            fixed: 'right',
            render: (h, param) => {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        // this.listData.splice(param.index, 1)
                        this.deleteStatus = true
                        this.deleteIndex = param.index
                      },
                    },
                  },
                  '删除'
                ),
              ])
            },
          })
        } else if (this.postForm.transfer_type == '2') {
          this.titleList = res.data.enable
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns2 = []
          this.listColumns2.unshift({
            title: '序号',
            align: 'center',
            type: 'index',
            width: 60,
            resizable: true,
          })
          this.listColumns2 = [...this.listColumns2, ...this.titleList]
          this.listColumns2 = JSON.parse(JSON.stringify(this.listColumns2))
          this.listColumns2.forEach((item, index) => {
            if (item.data_col == 'inventory_quantity') {
              let obj = {
                title: '数量',
                align: 'center',
                slot: 'inventory_quantity',
                width: 150,
                resizable: true,
              }
              this.$set(this.listColumns2, index, obj)
            } else if (item.data_col == 'current_unit_price') {
              let obj = {
                title: '调拨后单价',
                align: 'center',
                slot: 'current_unit_price',
                width: 200,
                resizable: true,
              }
              this.$set(this.listColumns2, index, obj)
            }
          })
          this.listColumns2.push({
            title: '操作',
            align: 'center',
            width: 80,
            fixed: 'right',
            render: (h, param) => {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',

                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        // this.listData.splice(param.index, 1)
                        this.deleteStatus = true
                        this.deleteIndex = param.index
                      },
                    },
                  },
                  '删除'
                ),
              ])
            },
          })
        }
        // 拣货修改表头的时候
        if (this.type) {
          // 预拣货可以修改时间参数
          let arr = [
            { key: 'batch_number', name: '批号' },
            { key: 'production_date', name: '生产日期' },
            { key: 'valid_period', name: '有效期' },
            { key: 'serial_number', name: '序列号' },
          ]
          if (this.postForm.transfer_type == '1') {
            this.listColumns1.forEach((item, index) => {
              arr.forEach(item2 => {
                if (item.data_col == item2.key) {
                  let obj = {
                    title: item2.name,
                    align: 'center',
                    slot: item2.key,
                    width: 150,
                    resizable: true,
                  }
                  this.$set(this.listColumns1, index, obj)
                }
              })
            })
          } else {
            this.listColumns2.forEach((item, index) => {
              arr.forEach(item2 => {
                if (item.data_col == item2.key) {
                  let obj = {
                    title: item2.name,
                    align: 'center',
                    slot: item2.key,
                    width: 150,
                    resizable: true,
                  }
                  this.$set(this.listColumns2, index, obj)
                }
              })
            })
          }
        }
      })
    },
    queryCode() {
      // if(this.listData.length == 0) {
      //   this.$Message.warning('请至少添加一组产品后再查询')
      //   return
      // }
      if (!this.codeStr) {
        this.$Message.warning('请输入条形码再查询')
        return
      }
      // 010694218038685417220804108685112391015  010694218038695317220809108695230091002
      let arr = this.codeStr.trim().split('\n')

      this.$http.post(this.$api.productSearchBarcode, { barcode_list: arr }, true).then(res => {
        this.codeStr = ''
        if (res.data.length == 0) {
          this.$Message.warning('清单中暂无匹配数据')
        } else {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < this.listData.length; j++) {
              if (res.data[i].name === this.listData[j].product_name && res.data[i].model_name === this.listData[j].model_name) {
                res.data[i].product_name = this.listData[j].product_name
                res.data[i].licence_code = this.listData[j].licence_code
                res.data[i].factory_name = this.listData[j].factory_name
              }
            }
          }
          let arr = []
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < this.listData.length; j++) {
              if (res.data[i].name === this.listData[j].product_name && res.data[i].model_name === this.listData[j].model_name && !this.listData[j].hadAdd && !res.data[i].hadAdd) {
                this.$set(this.listData[j], 'hadAdd', true)
                res.data[i].hadAdd = true
                this.listData[j].bar_code = res.data[i].bar_code
                this.listData[j].batch_number = res.data[i].batch_number
                this.listData[j].production_date = res.data[i].production_date ? res.data[i].production_date : this.listData[j].production_date
                this.listData[j].valid_period = res.data[i].valid_period ? res.data[i].valid_period : this.listData[j].valid_period
                this.listData[j].serial_number = res.data[i].serial_number
              } else if (res.data[i].name === this.listData[j].product_name && res.data[i].model_name === this.listData[j].model_name && !this.listData[j].hadAdd && !res.data[i].hadAdd && this.listData[j].serial_number != res.data[i].serial_number) {
                let row = res.data[i]
                res.data[i].quantity = 1
                this.$set(this.listData[j], 'hadAdd', true)
                res.data[i].hadAdd = true
                arr.push(row)
              } else if (res.data[i].name === this.listData[j].product_name && res.data[i].model_name === this.listData[j].model_name && this.listData[j].serial_number == res.data[i].serial_number) {
                res.data[i].hadAdd = true
              }
            }
          }
          for (let i = 0; i < res.data.length; i++) {
            if (!res.data[i].hadAdd) {
              let row = res.data[i]
              res.data[i].quantity = 1
              res.data[i].hadAdd = true
              arr.push(row)
            }
          }
          this.listData = [...this.listData, ...arr]
          this.listData = JSON.parse(JSON.stringify(this.listData))
          this.$Message.success('数据匹配完成')
        }
      })
      // console.log(this.listData, '数量')
    },
    // 确认删除
    sureDelete() {
      // console.log(this.listData, this.deleteIndex)
      let total = this.listData[this.deleteIndex].inventory_quantity

      // 销售申请转调拨
      if (this.type) {
        for (let i = 0; i < this.checkData.length; i++) {
          if (this.checkData[i].product_name == this.listData[this.deleteIndex].product_name && this.checkData[i].model_name == this.listData[this.deleteIndex].model_name && this.checkData[i].id == this.listData[this.deleteIndex].id) {
            // this.$set(this.checkData[i], 'picknum', total * 1 )
            this.checkData[i].picknum = this.checkData[i].picknum - total
          }
        }
      }
      this.listData.splice(this.deleteIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
    },
    // 删除
    deleteRow(index) {
      this.deleteIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗 数据还需要往下累计展示，而不是直接覆盖，需要数组拼接
    sureChoose() {
      let storArr = [] // 暂存数据
      if (!this.changeNum && this.hadSelectList.length == 0) {
        this.$Message.warning('请输入调拨数量或者至少选择一个产品')
        return
      }
      //   填了数字调用接口，否则以前逻辑
      if (this.changeNum) {
        let chosenData = {
          warehouse_id: this.warehouse_id,
          transfer_quantity: this.changeNum,
          product_id: this.queryFrom.product_id ? this.queryFrom.product_id : null,
          specification_id_str: this.queryFrom.specification_id_str[0] ? this.queryFrom.specification_id_str[0] : null,
          batch_number: this.queryFrom.batch_number ? this.queryFrom.batch_number : null,
        }
        this.$http.get(this.$api.transferPicking, chosenData, false).then(res => {
          this.hadSelectList = res.data
          this.hadSelectList.forEach(item => {
            if (item.production_date) {
              item.production_date = this.$moment(item.production_date * 1000).format('YYYY-MM-DD')
            } else {
              item.production_date = null
            }
            if (item.valid_period) {
              item.valid_period = this.$moment(item.valid_period * 1000).format('YYYY-MM-DD')
            } else {
              item.valid_period = null
            }
            if (item.production_license_valid_period) {
              item.production_license_valid_period = this.$moment(item.production_license_valid_period * 1000).format('YYYY-MM-DD')
            } else {
              item.production_license_valid_period = null
            }
            if (item.business_license_valid_period) {
              item.business_license_valid_period = this.$moment(item.business_license_valid_period * 1000).format('YYYY-MM-DD')
            } else {
              item.business_license_valid_period = null
            }
            if (item.licence_valid_period) {
              item.licence_valid_period = this.$moment(item.licence_valid_period * 1000).format('YYYY-MM-DD')
            } else {
              item.licence_valid_period = null
            }
            if (item.production_record_date) {
              item.production_record_date = this.$moment(item.production_record_date * 1000).format('YYYY-MM-DD')
            } else {
              item.production_record_date = null
            }
            if (item.record_valid_period) {
              item.record_valid_period = this.$moment(item.record_valid_period * 1000).format('YYYY-MM-DD')
            } else {
              item.record_valid_period = null
            }
          })
          if (this.listData.length > 0) {
            // console.log(123)
            // let select = this.listData.map(item => item.product_id + item.specification_id + item.serial_number)
            // this.hadSelectList = this.hadSelectList.filter(i => {
            //   return !select.includes(i.product_id + i.specification_id + i.serial_number)
            // })

            // let select = this.listData.map(item => item.batch_number)
            // this.hadSelectList = this.hadSelectList.filter(i => {
            //   return !select.includes(i.batch_number)
            // })
            // let arr = [ ...this.hadSelectList]
            // this.listData = JSON.parse(JSON.stringify(arr))
            this.listData = JSON.parse(JSON.stringify(this.hadSelectList))
          } else {
            // console.log(123444)
            this.listData = JSON.parse(JSON.stringify(this.hadSelectList))
          }
          this.inventory = false
          this.inputFlag = false
          this.changeNum = 0
        })
      } else {
        // 做拣货选择
        if (this.type) {
          // let n = this.Number
          // // 当可填数量大于0 当情况，勾选数量以此递减
          // if (n > 0) {
          //   for (var i = 0; i < this.hadSelectList.length; i++) {
          //     // console.log(this.hadSelectList[i].inventory_quantity)
          //     // 如果当前的小于
          //     if (this.hadSelectList[i].quantity < n) {
          //       storArr.push(this.hadSelectList[i])
          //       n = n - this.hadSelectList[i].inventory_quantity
          //     } else {
          //       this.hadSelectList[i].inventory_quantity = n
          //       storArr.push(this.hadSelectList[i])
          //       break
          //     }
          //   }
          // } else {
          //   // 可填数量小于等于0， 勾选当数量为0
          //   for (let i = 0; i < this.hadSelectList.length; i++) {
          //     this.hadSelectList[i].inventory_quantity = 0
          //     storArr.push(this.hadSelectList[i])
          //   }
          // }
          // this.hadSelectList = JSON.parse(JSON.stringify(storArr))
          // // 数量转换
          // this.hadSelectList.forEach(item => {
          //   item.quantity = item.inventory_quantity
          // })
        }
        if (this.listData.length > 0) {
          // let select = this.listData.map(item => item.product_id + item.specification_id + item.serial_number)
          // this.hadSelectList = this.hadSelectList.filter(i => {
          //   return !select.includes(i.product_id + i.specification_id + i.serial_number)
          // })

          let select = this.listData.reduce((result,item) => {
            if(item.id == this.selectRowId){
              result.push(item.batch_number)
            }
            return result
          },[])
          this.hadSelectList = this.hadSelectList.filter(i => {
            return !select.includes(i.batch_number)
          })
          let arr = [...this.listData, ...this.hadSelectList]
          this.listData = JSON.parse(JSON.stringify(arr))
        } else {
          this.listData = JSON.parse(JSON.stringify(this.hadSelectList))
        }

        this.listData.forEach((e, i) => {
          this.listData[i].product_unit_price = e.unit_price
          let num = this.listData[i].quantity ? this.listData[i].quantity : 0
          this.listData[i].number = this.listData[i].quantity
          this.$set(this.listData[i], 'quantity', num)
          this.$set(this.listData[i], 'current_unit_price', this.listData[i].current_unit_price ? this.listData[i].current_unit_price : 0)
        })
        // 销售申请单转调拨单的调拨数量
        for (var i = 0; i < this.hadSelectList.length; i++) {
          for (var j = 0; j < this.checkData.length; j++) {
            if (this.hadSelectList[i].product_model_code == this.checkData[j].product_model_code && this.hadSelectList[i].id == this.checkData[j].id) {
              this.$set(this.checkData[j], 'picknum', this.hadSelectList[i].inventory_quantity + this.checkData[j].picknum)
            }
          }
        }
        this.inventory = false
        this.inputFlag = false
        this.changeNum = 0
      }
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
      //   变化的时候会返回被选中的所有数据
      if (this.hadSelectList.length > 0) {
        this.inputFlag = true
      } else {
        this.inputFlag = false
      }
    },
    // 点击确认
    post() {
      let to_warehouse_name='';
      this.storeList.forEach(item => {
        if (item.warehouse_id == this.postForm.to_warehouse_id) {
          to_warehouse_name = item.warehouse_name
        }
      })
      if(to_warehouse_name=='不合格仓'){
        if(this.postForm.note==''){
          this.$Message.warning('转不合格仓请填写备注')
          return null
        }
      }
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发起仓库')
        return null
      }
      if (!this.postForm.to_warehouse_id) {
        this.$Message.warning('请选择调拨仓库')
        return null
      }
      if (!this.postForm.processor_id) {
        this.$Message.warning('请选择经手人')
        return null
      }
      if (!this.postForm.reviewer_id) {
        this.$Message.warning('请选择审核人')
        return null
      }
      if (this.listData.length == 0) {
        this.$Message.warning('请至少添加一组产品调拨')
        return null
      }
      if (this.postForm.transfer_type == '2') {
        for (let i = 0; i < this.listData.length; i++) {
          if (!this.listData[i].current_unit_price && this.listData[i].current_unit_price !== 0 && !this.listData[i].quantity) {
            this.$Message.warning(`请填写${i + 1}组产品的数量、销售单价再进行调拨`)
            return null
          }
        }
      }
      this.listData.forEach((item, i) => {
        this.listData[i].quantity = this.listData[i].inventory_quantity
      })
      // 销售申请单转调拨单需要做验证
      if (this.type) {
        for (let i = 0; i < this.listData.length; i++) {
          if (!this.listData[i].quantity || !this.listData[i].batch_number || !this.listData[i].valid_period) {
            this.$Message.warning(`请完善第${i + 1}组产品的批号/数量/有效期再提交`)
            return
          }
          let resCode = this.$utils.dataType(this.listData[i].valid_period)
          if (resCode.code) {
            this.$Message.warning(resCode.text)
            return
          }
        }
      }
      // //获取所有订单数量
      // let ddQuantity = this.checkData.reduce((result,item)=>{
      //   return result + item.quantity
      // },0)
      // //获取所有调拨数量
      // let dbQuantity = this.listData.reduce((result,item)=>{
      //   return result + item.quantity
      // },0)
      // //调拨数量大于订单数量
      // if(this.type){
      //   if(dbQuantity>ddQuantity){
      //     this.$Message.warning(`当前调拨数量大于订单数量请确认后再提交`)
      //     return
      //   }
      // }
      let result = []
      this.checkData.forEach((item,index)=>{
        if(item.picknum > item.quantity){
          result.push(index+1)
        }
      })
      if(result.length){
        this.$Message.warning(`产品清单第${result[0]}项调拨数量大于订单数量请确认后再提交`)
        return
      }
      this.postForm.line_info = this.listData
      this.postForm.from_warehouse_id = this.warehouse_id
      this.postStatus = true
    },
    // 确认调拨
    surePost: debounce(
      function () {
        if (this.flag) return
        this.flag = true
        if (this.type) {
          this.storeList.forEach(item => {
            if (item.warehouse_id == this.postForm.from_warehouse_id) {
              this.postForm.from_warehouse_name = item.warehouse_name
            }
          })
          let obj = {
            id: this.$route.query.id,
            from_warehouse_id: this.postForm.from_warehouse_id,
            from_warehouse_name: this.postForm.from_warehouse_name,
            to_warehouse_id: this.postForm.to_warehouse_id,
            processor_id: this.postForm.processor_id,
            reviewer_id: this.postForm.reviewer_id,
            processor: this.postForm.processor,
            reviewer: this.postForm.reviewer,
            line_info: this.postForm.line_info,
            note: this.postForm.note,
            original_info_info: this.checkData
          }
          console.log(obj,'obj')
          this.$http
            .put(this.$api.warehouseTransferEdit, obj, true)
            .then(res => {
              this.$Message.success('提交成功')
              this.postStatus = false
              this.flag = false
              this.$router.go(-1)
            })
            .catch(err => {
              this.postStatus = false
              this.flag = false
            })
        } else {
          this.$http
            .post(this.$api.warehouseTransfer, this.postForm, true)
            .then(res => {
              this.$Message.success('提交成功')
              this.postStatus = false
              this.flag = false
              this.$router.go(-1)
            })
            .catch(err => {
              this.postStatus = false
              this.flag = false
            })
        }
        sessionStorage.setItem('updataCache', '0')
      },
      1000,
      true
    ),
    // 产品名称改变
    productChange(e) {
      this.queryFrom.specification_id_str = []
      this.modelList = []
      // if (!e) return
      this.queryItemnumber(e)
    },
    // 弹窗列表
    query() {
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发起仓库')
        return null
      }

      // if (!this.queryFrom.product_id) {
      //   this.$Message.warning('请选择产品名称')
      //   return null
      // }
      this.hadSelectList = []
      this.$http.get(this.$api.inventoryProductLookup, { warehouse_id: this.warehouse_id, product_id: this.queryFrom.product_id, specification_id_str: this.queryFrom.specification_id_str.join(','), batch_number: this.queryFrom.batch_number, dynamic_header: '1', lookup: '1' }).then(res => {
        if (res.data.length > 0) {
          this.inventoryData = res.data
          for (let i = 0; i < this.inventoryData.length; i++) {
            this.inventoryData[i].production_license_valid_period = this.inventoryData[i].production_license_valid_period ? this.$moment(this.inventoryData[i].production_license_valid_period * 1000).format('YYYY-MM-DD') : null
            this.inventoryData[i].business_license_valid_period = this.inventoryData[i].business_license_valid_period ? this.$moment(this.inventoryData[i].business_license_valid_period * 1000).format('YYYY-MM-DD') : null
            this.inventoryData[i].create_time = this.inventoryData[i].create_time ? this.$moment(this.inventoryData[i].create_time * 1000).format('YYYY-MM-DD') : null
            this.inventoryData[i].end_time = this.inventoryData[i].end_time ? this.$moment(this.inventoryData[i].end_time * 1000).format('YYYY-MM-DD') : null
            this.inventoryData[i].production_record_date = this.inventoryData[i].production_record_date ? this.$moment(this.inventoryData[i].production_record_date * 1000).format('YYYY-MM-DD') : null
            this.inventoryData[i].record_valid_period = this.inventoryData[i].record_valid_period ? this.$moment(this.inventoryData[i].record_valid_period * 1000).format('YYYY-MM-DD') : null
            this.inventoryData[i].licence_valid_period = this.inventoryData[i].licence_valid_period ? this.$moment(this.inventoryData[i].licence_valid_period * 1000).format('YYYY-MM-DD') : null
            this.inventoryData[i].quantity = this.inventoryData[i].inventory_quantity
            this.inventoryData[i].original_unit_price = this.inventoryData[i].unit_price
            // 判断是录入的是年月还是年月日
            this.inventoryData[i].production_date = this.inventoryData[i].production_date ? this.$moment(this.inventoryData[i].production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : null
            this.inventoryData[i].production_date = this.inventoryData[i].production_date ? this.$utils.Timetransformation(this.inventoryData[i].production_date).replace(/-/gi, '') : null
            this.inventoryData[i].warning = this.$utils.warningDay(this.inventoryData[i].valid_period, 180)
            this.inventoryData[i].valid_period = this.inventoryData[i].valid_period ? this.$moment(this.inventoryData[i].valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : null
            this.inventoryData[i].valid_period = this.inventoryData[i].valid_period ? this.$utils.Timetransformation(this.inventoryData[i].valid_period).replace(/-/gi, '') : null
            this.listData.forEach(item => {
              if (item.id === this.inventoryData[i].id) {
                this.inventoryData[i].hasChecked = true
              }
            })
          }
          this.inventory = true
        } else {
          this.$Message.warning('暂无数据')
          return null
        }
        if (this.changeNum) {
          for (let i = 0; i < this.inventoryData.length; i++) {
            this.$set(this.inventoryData[i], '_disabled', true)
          }
        }
        if (!this.changeNum && this.queryFrom.specification_id_str.length > 0 && this.queryFrom.product_id) {
          this.inputFlag = false
        }
      })
      // this.$http.get(this.$api.inventoryProductLookup, { batch_number: this.queryFrom.batch_number, product_id: this.queryFrom.product_id, warehouse_id: this.warehouse_id, specification_id_str: this.queryFrom.specification_id_str.join(','), lookup: '1' }, true).then(res => {
      //   if (res.data.length > 0) {
      //     this.inventoryData = res.data
      //     for (let i = 0; i < this.inventoryData.length; i++) {
      //       this.inventoryData[i].quantity = this.inventoryData[i].inventory_quantity
      //       this.inventoryData[i].original_unit_price = this.inventoryData[i].unit_price
      //       this.inventoryData[i].validPeriod = this.inventoryData[i].valid_period ? this.$moment(this.inventoryData[i].valid_period * 1000).format('YYYY-MM-DD') : ''
      //     }
      //     this.inventory = true
      //   } else {
      //     this.$Message.warning('暂无数据')
      //     return null
      //   }
      // })
    },
    // 查询产品列表和sku列表
    queryProduct(id = null, subId = null) {
      this.$http.get(this.$api.inventoryProductLookup, { product_id: subId, warehouse_id: this.warehouse_id }, false).then(res => {
        // if (!subId) {
        this.productList = res.data
        // } else {
        // this.modelList = res.data
        // }
      })
    },
    // 根据仓库查sku
    queryItemnumber(subId) {
      this.$http.get(this.$api.inventoryCodeLookup, { product_id: subId, warehouse_id: this.warehouse_id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 经手人改变
    processorChange(e) {
      if (e >= 0) {
        for (let i = 0; i < this.dealList.length; i++) {
          if (e == this.dealList[i].id) {
            this.postForm.processor = this.dealList[i].user_name
          }
        }
      } else {
        this.postForm.reviewer_id = ''
      }
    },
    // 审核人
    specialUser(num) {
      this.$http.get(this.$api.publicSpecialUser, { user_type: num }, true).then(res => {
        if (num == '1') {
          this.dealList = res.data
        } else {
          this.reviewList = res.data
        }
      })
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    upload() {
      this.$Message.warning('功能开发中...')
    },
    // 查询仓库列表
    queryStoreList() {
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.listData[index], name, e.target.value.trim())
    },
    changeIpt1(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.listData[index], name, 1)
      } else {
        this.$set(this.listData[index], name, e.target.value.trim() * 1)
      }
    },
    changeTotal(e, name, index, product_name, model_name, id) {
      this.$set(this.listData[index], name, e * 1)
      // 销售申请转调拨
      if (this.type) {
        let total = 0
        for (let i = 0; i < this.listData.length; i++) {
          if (this.listData[i].product_name == product_name && this.listData[i].model_name == model_name && this.listData[i].id == id) {
            total = total * 1 + this.listData[i].inventory_quantity * 1
          }
        }
        for (let i = 0; i < this.checkData.length; i++) {
          if (this.checkData[i].product_name == product_name && this.checkData[i].model_name == model_name && this.checkData[i].id == id) {
            this.$set(this.checkData[i], 'picknum', total * 1)
          }
        }
      }
    },
    changeIpt2(e, index) {
      this.$set(this.checkData2[index], 'picking_quantity', e * 1)
    },
  },
}
</script>

<style scoped lang="less">
.redBox {
  color: red;
  margin-left: 30px;
}
.checkList {
  margin-bottom: 30px;
  .picking {
    text-align: center;
    color: #ccc;
  }
}
.page {
  display: flex;
  flex-direction: column;

  .pageTop {
    overflow-y: auto;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 22px 0;

    .item {
      width: 325px;
      text-align: right;

      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }
  }

  .btm {
    flex: 1;
    display: flex;

    .btmRight {
      flex: 1;
      padding: 23px;
      border-top: 1px solid #e8eaec;
      display: flex;
      flex-direction: column;
      overflow: auto;
      p {
        margin-bottom: 23px;
        font-size: 18px;
        color: #525b6d;
      }

      .summary > span:first-child {
        margin-right: 30px;
      }

      .tipsDiv {
        margin-top: 20px;
        border: 1px solid #e8eaec;
        height: 230px;
        display: flex;
        .tipsFl {
          width: 210px;
          line-height: 230px;
          padding-left: 33px;
          border-right: 1px solid #e8eaec;
          background: #f8f8f9;
          color: #525b6d;
          font-size: 14px;
        }
        /deep/ .tipsFr {
          flex: 1;
          display: flex;
          padding: 8px 15px 8px 23px;
          .ivu-input-wrapper {
            height: 100% !important;
          }
          .ivu-input {
            height: 100% !important;
          }
        }
      }

      .button {
        height: 36px;
        line-height: 36px;
        text-align: right;
        margin-top: 20px;
      }
    }
  }
}
.marginRight40 {
  margin-right: 40px;
}
.marginLeft16 {
  margin-left: 16px;
}
.changeNum {
  margin-bottom: 13px;
}
.flexBox{
  display: flex;
  align-items: center;
  .redBox{
    flex: 1;
    text-align: right;
    margin-right: 30px;
  }
}
</style>
